<template>
    <el-upload :limit="1"
               ref="upload"
               style="display: inline-block"
               action="https://900etrip.com"
               :loading="loading"
               :auto-upload="false"
               accept=".xlsx"
               :show-file-list="false"
               :on-change="handleChange">
        <slot></slot>
    </el-upload>
</template>

<script type="text/ecmascript-6">
import orderUrls from '@/www/urls/orderUrls'
import {uploadExcel} from "@/common/js/Utils";
import dayjs from "dayjs";

const keys = {
    "用车城市": "startCityName",
    "用车人姓名": "tripUserName",
    "用车人手机": "tripUserMobile",
    "上车详细地址": "departure",
    "下车详情地址": "destination",
    "订单金额": "orderAmount",
    "结算金额": "settleAmount",
    "备注": "orderRemake",
    "航班号": "flightNumber",
    "订单编号": "thirdOrderId"
}

export default {
    name: 'BtnOrderImport',
    //定义模版数据
    data() {
        return {
            loading: false
        }
    },
    //定义事件方法
    methods: {
        async handleChange(file) {
            try {
                this.loading = true
                const data = await uploadExcel(file.raw);
                console.log(data);
                const form = [];
                let msg;
                if (data.length === 0)
                    msg = '请导入订单数据'
                if (!data.every((item) => item['订单编号']))
                    msg = "订单编号不能为空";
                if (!data.every((item) => ['经济', '舒适'].includes(item['车型'].trim())))
                    msg = "车型请填写（经济、舒适）";
                if (!data.every((item) => item['结算金额']))
                    msg = "结算金额不能为空";
                if (!data.every((item) => item['订单金额']))
                    msg = "订单金额不能为空";
                if (!data.every((item) => item['下车详情地址']))
                    msg = "下车详情地址不能为空";
                if (!data.every((item) => item['上车详细地址']))
                    msg = "上车详细地址不能为空";
                if (!data.every((item) => item['用车人手机']))
                    msg = "用车人手机不能为空";
                if (!data.every((item) => item['用车人姓名']))
                    msg = "用车人姓名不能为空";
                if (!data.every((item) => item['出发时间']))
                    msg = "出发时间不能为空";
                if (!data.every((item) => ['长春'].includes(item['用车城市'].trim())))
                    msg = "用车城市只限长春";
                if (!data.every((item) => ['送机', '接机'].includes(item['订单类型'].trim())))
                    msg = "订单类型请填写（送机、接机）";

                this.loadingShow({
                    text: '订单导入中，请不要关闭...'
                })
                for (let item of data) {
                    const it = {
                        productType: 1,
                        productFrom: item['订单类型'].trim() === '送机' ? 7 : 8,
                        refType: 3,
                        resourcePriceWay: 0,
                        vehicleType: item['车型'].trim() === '经济' ? 1 : 2,
                        playPersonNumber: 1,
                    }
                    for (let key in item) {
                        if (keys[key])
                            it[keys[key]] = typeof item[key] === 'string' ? item[key].trim() : item[key]
                        if ('出发时间' === key) {
                            let dateTime = dayjs(item['出发时间']).valueOf();
                            it["reserveTime"] = dateTime;
                            if (isNaN(dateTime) || typeof item['出发时间'] !== 'string')
                                msg = '请将出发时间列单元格格式设置为文本格式'
                        }
                    }
                    form.push(it)
                }
                if (msg)
                    return this.$alert(`导入数据有误，${msg}`, '订单导入失败')
                console.log(form);
                const ret = await orderUrls.importBackendCarOrderApi(form)
                this.loadingHide()
                if (!ret.success)
                    throw ret.errors[0].message
                try {
                    await this.$confirm('订单导入成功！', '提示', {
                        confirmButtonText: "前往订单列表",
                        cancelButtonText: '继续添加',
                    })
                    this.$router.push('/userCarList')
                } finally {
                    console.log("完成");
                }
            } catch (err) {
                this.$alert(err.toString(), '导入失败')
            } finally {
                this.loading = false
                this.$refs['upload'].clearFiles();
                this.loadingHide()
            }
        }
    }
}
</script>
